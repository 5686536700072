import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { Switch } from '@mui/material';
import { Sun, Moon } from 'react-feather';
import { GlobalState } from '../../store/global';
import { setInitialTheme } from '../../helpers';

const CustomSwitch = styled(Switch)`
  margin-right: 12px;

  .MuiSwitch-switchBase{
    transform: translateY(4px);
    background-color: #00004d;
    border: 1px solid #EEEEEE;
    height: 28px;
    width: 28px;
    border: none;
    padding: 0px;
  }

  .MuiSwitch-switchBase.Mui-checked{
    background: radial-gradient(white, rgb(243, 208, 92));
    border: none;
    transform: translate(28px, 4px);
  }

  &:hover{
    .MuiSwitch-switchBase{
      background-color: #00004d;
      border: none;
  
    }
  
    .MuiSwitch-switchBase.Mui-checked{
      background-color: #fff9c4;
      border: none;
    }
  }
`;

const CustomMoon = styled(Moon)`
  color: white;
  height: 20px;
  width: 20px;
`;

const CustomSun = styled(Sun)`
  color: orange;
  height: 18px;
  width: 18px;
`;

const SelectMode = () => {
  const { themeLight, setThemeLight } = useContext(GlobalState);

  return (
    <CustomSwitch
      size="large"
      checked={themeLight}
      onChange={() => {
        setThemeLight(p => !p);
        setInitialTheme('light');
      }}
      checkedIcon={<CustomSun />}
      icon={<CustomMoon />}
    />
  );
};

export default SelectMode;
