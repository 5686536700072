/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import GlobalStateProvider from './store/global';
import { AuthProvider } from './store/auth';
import { SnackProvider } from './store/snack';
import { createRoot } from 'react-dom/client';



const ReactQueryConfig = {
  defaultOptions: {
    queries: {
      // only retry failed queries once //
      retry: 1,
      // dont refetch on focus
      refetchOnWindowFocus: false,

      // Other Options
      // staleTime: 1000 * 30,// 30seconds
      // cacheTime: 1000 * 30, //30 seconds
      // refetchOnMount: "always",
      // refetchOnReconnect: "always",
      // refetchInterval: 1000 * 30, //30 seconds
      // refetchIntervalInBackground: false,
      // suspense: false,
    },
  },
};

const container = document.getElementById('root');
const root = createRoot(container)

const queryClient = new QueryClient(ReactQueryConfig);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <GlobalStateProvider>
          <AuthProvider>
            <SnackProvider>
              <StyledEngineProvider injectFirst>
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
              </StyledEngineProvider>
            </SnackProvider>
          </AuthProvider>
        </GlobalStateProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
