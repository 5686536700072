/* eslint-disable */
import React from 'react';
import { 
  InputAdornment, 
} from '@mui/material';
import { Lock } from 'react-feather';
import { CustomTextField } from '../../../styles/forms';

const ConfirmPasswordField = ({
  values, 
  touched, 
  handleChange,
  errors,
  handleBlur,
}) => {
  return (
    <CustomTextField
      label="Confirm Password"
      type="password"
      name="confirmPassword"
      variant="outlined"
      value={values.confirmPassword}
      color="secondary"
      onBlur={handleBlur}
      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
      onChange={handleChange}
      helperText={touched.confirmPassword && errors.confirmPassword}
      fullWidth
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Lock />
          </InputAdornment>
        ),
      }}
      sx={{
        mb: 4,
      }}
    />
  )
}

export default ConfirmPasswordField;
