/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { Paper, Button } from "@mui/material";
import { XSquare } from "react-feather";

const Wrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 2px solid firebrick;
`;

const ErrorModal = ({ error, handleClose }) => {
  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 6,
          justifyContent: "flex-start",
        }}
      >
        <XSquare
          color="firebrick"
          height={18}
          width={18}
          style={{ transform: "translateY(-1px)" }}
        />

        <p style={{ fontWeight: "600", fontSize: 15 }}>{error?.title}</p>
      </div>
      <div style={{ height: 8 }} />
      <p style={{ fontSize: 13 }}>{error?.body}</p>
      <pre
        style={{
          fontFamily: "inherit",
          textAlign: "start",
          overflowX: "auto",
          whiteSpace: "pre-wrap",
          fontSize: 13,
        }}
      >
        {error?.context}
      </pre>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingTop: 12,
        }}
      >
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          onClick={handleClose}
        >
          go back
        </Button>
      </div>
    </Wrapper>
  );
};

export default ErrorModal;
