/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  LinearProgress,
  Button,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { User, Lock, ThumbsUp } from "react-feather";
import styled from "styled-components/macro";
import LOGO from "../../assets/primary-vertical.png";
import API from "../../axios/API";
import useSnack from "../../store/snack";
import useAuth from "../../store/auth";
import { CustomTextField } from "../../styles/forms";
import { useLocation } from "react-router-dom";

const FormContainer = styled(Paper)`
  width: 600px;
  height: 340px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    width: 96%;
    height: 340px;
  }
`;

const Image = styled.img`
  width: 80%;
  height: 200px;
  object-fit: contain;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const TopHalf = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-width: 260px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  flex-direction: column;
  flex: 0.4;
  background-color: ${(props) => props.theme.palette.background.contrast};

  @media (max-width: 600px) {
    display: none;
  }
`;

const BottomHalf = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  flex: 0.6;
  min-width: 300px;

  @media (max-width: 600px) {
    flex: 1;
    min-width: 200px;
  }
`;

const StyledButton = styled(Button)`
  border: 1px solid black;
  color: black;
`;

const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const RegisterSpan = styled.span`
  font-size: 11px;
  margin-top: 0px;

  a {
    color: ${(props) => props.theme.palette.info.dark};
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const InitialValues = {
  email: "",
  password: "",
};

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const POSSIBLE_SIGNIN_ERRORS = {
  invalid_password: "The password given for this account was incorrect",
  inactive_account:
    "The account you are trying to sign into has been flagged inactive",
  user_not_found: "No account found with this email address",
};

const getSigninFailMessage = (e) => {
  try {
    const { error: parsedErrorMsg } = e?.response?.data || {};
    const msg = POSSIBLE_SIGNIN_ERRORS[parsedErrorMsg];
    return msg || "Failed to login";
  } catch (error) {
    return "Failed to login";
  }
};

const FromAcceptInvitePage = ({ email = null }) => {
  return (
    <div
      style={{
        width: "100%",
        background: "white",
        border: "1px solid lightgray",
        minHeight: 70,
        borderRadius: 4,
        marginTop: 8,
        padding: 12,
        display: "flex",
      }}
    >
      <div
        style={{
          flexBasis: 60,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ThumbsUp color="mediumseagreen" />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <p style={{ fontWeight: "600", fontSize: 14 }}>
          The invitation was successfully accepted
        </p>
        <p style={{ fontSize: 12, fontWeight: "500", color: "darkgrey" }}>
          Login above with your account email {email ? `(${email})` : ""} and
          password
        </p>
      </div>
    </div>
  );
};

const SignIn = () => {
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const { login } = useAuth();

  const location = useLocation();

  const fromInvite = Boolean(location?.state?.fromInvite);

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const { data } = await API.post("/token-auth/", {
        username: values.email,
        password: values.password,
        version: 2,
      });
      setSubmitting(false);
      setSnack(`Successfully logged in ${values.email}`, SNACK_TYPES.SUCCESS);
      return login(data.token);
    } catch (error) {
      const msg = getSigninFailMessage(error);
      setSnack(msg, SNACK_TYPES.ERROR);
      return setSubmitting(false);
    }
  };

  return (
    <FormContainer>
      <TopHalf>
        <Image src={LOGO} alt="spiffy-logo" />
      </TopHalf>
      <BottomHalf>
        <Formik
          initialValues={InitialValues}
          validationSchema={ValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            handleSubmit,
          }) => (
            <Form
              noValidate
              onSubmit={handleSubmit}
              style={{ display: "flex" }}
            >
              <Typography variant="h2" sx={{ mt: 2 }} align="left">
                Log In
              </Typography>
              <CustomTextField
                type="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                variant="standard"
                color="secondary"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <User />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 4,
                  mt: 8,
                }}
              />
              <CustomTextField
                label="Password"
                type="password"
                name="password"
                variant="standard"
                color="secondary"
                onBlur={handleBlur}
                error={Boolean(touched.password && errors.password)}
                onChange={handleChange}
                helperText={touched.password && errors.password}
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  mb: 6,
                }}
                value={values.password}
              />
              <StyledButton
                type="submit"
                fullWidth
                variant="outlined"
                disabled={isSubmitting}
                sx={{ p: 1, fontSize: 14, mb: 2 }}
              >
                {isSubmitting ? "..." : "Submit"}
              </StyledButton>
              {isSubmitting && (
                <LinearProgress variant="indeterminate" color="secondary" />
              )}
              <div style={{ marginTop: "auto" }} />
              <RegisterSpan>
                Dont have an account yet ? <Link to="/register/">Sign Up</Link>{" "}
              </RegisterSpan>
              <RegisterSpan>
                Forgot Your Password ? <Link to="/forgot-password/">Reset</Link>{" "}
              </RegisterSpan>
            </Form>
          )}
        </Formik>
      </BottomHalf>
      {fromInvite && (
        <FromAcceptInvitePage email={location?.state?.email || null} />
      )}
    </FormContainer>
  );
};

export default SignIn;
