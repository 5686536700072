/* eslint-disable */
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import overrides from './overrides';
import typography from './typography';

const SPIFFY_BLUE = 'rgb(51, 122, 183)';

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1440,
  },
};

const props = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiButton: {
    disableRipple: true,
  },
};



const theme = createTheme({
  spacing: 4,
  breakpoints,
  components: overrides,
  props,
  typography,
  palette: {
    mode: 'light',
    primary: {
      light: 'rgba(0, 0, 0, .6)',
      main: SPIFFY_BLUE,
      brand: 'rgb(51, 122, 183)',
    },
    secondary: {
      main: 'rgb(0, 0, 0)',
    },
    action: {
      main: 'mediumseagreen',
    },
    nav: {
      background: grey[300],
      toolbar: '#FFF',
      activeLink: grey[400],
    },
    background: {
      main: '#F7F9FC',
      contrast: grey[200],
      wb: '#FFF',
      bw: '#000',
      bg: '#000',
      paper: '#FFF',
      light: grey[100],
    },
    highlight: {
      light: 'rgba(255,215,0, 0.3)',
      main: 'rgba(255,215,0, 0.6)',
      dark: 'rgb(255,215,0)',
      success: '#01b701',
    },
    loader: '#000',
    boxShadow: {
      main: '1px 1px 6px 1px rgba(0, 0, 0, .12)',
    },
    option: {
      // selected: '#DAE3ED',
      selected: '#94bee22b',
    },
  },
});

// const themeDark = createTheme({
//   spacing: 4,
//   breakpoints,
//   components: overrides,
//   props,
//   typography,
//   palette: {
//     mode: 'dark',
//     primary: {
//       light: '#FFF',
//       main: '#FFF',
//       brand: 'rgb(77, 172, 225)',
//     },
//     nav: {
//       background: '#001126',
//       toolbar: '#1a2635',
//       activeLink: '#1a2635',
//     },
//     secondary: {
//       light: grey[100],
//       main: grey[200],
//       dark: grey[400],
//     },
//     background: {
//       main: '#081f37',
//       contrast: '#1a2635',
//       wb: '#000',
//       bw: '#FFF',
//       bg: grey[200],
//       paper: '#0a2d4a',
//       light: '#103a5c',
//     },
//     highlight: {
//       light: '#00b8f794',
//       main: '#0fc0fc',
//       dark: '#0fc0fc',
//       success: '#98fb98',
//     },
//     loader: '#0fc0fc',
//     boxShadow: {
//       main: '1px 1px 10px 2px #051d32',
//       secondary: '1px 1px 4px 2px #051d32',
//     },
//     option: {
//       selected: '#00b8f794',
//     },
//   },
// });

// const getTheme = lightTheme => {
//   if (lightTheme) {
//     return theme;
//   }
//   return themeDark;
// };


const getTheme = () => theme

export default getTheme;
