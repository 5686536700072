/* eslint-disable */
import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import EmailField from '../Inputs/EmailField';
import PasswordField from '../Inputs/PasswordField';
import ReferralCodeField from '../Inputs/ReferralCodeField';
import { Button, Typography, Alert } from '@mui/material';
import useAuth from '../../../store/auth';
import API from '../../../axios/API';
import { ArrowLeft } from 'react-feather';


const ValidationSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Must be a valid email')
    .required('Email is required'),
  password: Yup
    .string()
    .required('Password is required'),
  ref: Yup
    .string()
    .required('Referral code is required')
})

const getErrorMsg = err => {
  try {
    const errorMsgFromServer = err?.response?.data?.error;
    if (!errorMsgFromServer) return 'Error trying to accept referral. Please Try Again.';

    if (errorMsgFromServer === 'referral_code_not_found') {
      return 'This referral code does not exist.';
    }

    if (errorMsgFromServer === 'user_not_found') {
      return 'No user found with this email. Please double-check email and retry.';
    }

    if (errorMsgFromServer === 'incorrect_password') {
      return 'Password for this email is incorrect. Please double-check password and retry.';
    }

    return 'Error trying to accept referral. Please try again';
  } catch (error) {
    return 'Error trying to accept referral. Please try again';
  }
};

const getIsFormDisabled = (isSubmitting, errors, values) => {
  if (isSubmitting) return true;

  if (Object.values(errors).length > 0) return true;

  if(!values.email || !values.password || !values.ref) return true;
 

  return false;
}


const AcceptForm = ({
  token,
  setStep,
}) => {

  const [errorMsg, setErrorMsg] = useState(null);
  const { login } = useAuth();

  const InitialValues = {
    email: '',
    password: '',
    ref: token,
  }

  const handleFormSubmit = async (values, { setSubmitting}) => {
    try {
      setSubmitting(true)
      const { data } = await API.post('/dashboard/referrals/use-referral-code/', {
        username: values.email,
        password: values.password,
        referral_code: values.ref,
      });
      const { tokens } = data;
      const { token } = tokens;
      setSubmitting(false)
      return login(token);
      
    } catch (error) {
      const errorMsg = getErrorMsg(error)
      setErrorMsg(errorMsg)
      setSubmitting(false)
    }
  }

  return (
    <>
      <Typography sx={{ fontSize: 20, fontWeight: '600', color: 'black'}} align="center">
        Spiffy Referral
      </Typography>
      <Typography style={{ fontSize: 12, color: 'darkgrey', marginBottom: 20}} align="center">
        Fill out your account information to accept referral
      </Typography>
    <Formik
      initialValues={InitialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        errors, 
        values, 
        touched, 
        handleChange, 
        handleBlur, 
        handleSubmit,
        isSubmitting,
      }) => (
        <form noValidate onSubmit={handleSubmit}>         
          <EmailField
            errors={errors}
            values={values}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}

          />
          <PasswordField 
            errors={errors}
            values={values}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <ReferralCodeField
            errors={errors}
            values={values}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            isOptional={false}
            disabled
          />
        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="small"
          type="submit"
          mt={3}
          disabled={getIsFormDisabled(isSubmitting, errors, values)}
        >
          {
            isSubmitting ? '...' : 'Accept Referral'
          }
          
        </Button>
        </form>
      )}
    </Formik>
    {
      errorMsg && (
        <Alert severity="error" size="small" sx={{ mt: 2, lineHeight: 1.2}} onClose={() => setErrorMsg(null)}>
          <span>{errorMsg}</span>
        </Alert>
      )
    }
    <div 
      style={{ alignSelf: 'center', padding: 4, marginTop: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4}}
      onClick={() => setStep(0)}
    >
      <ArrowLeft style={{ height: 12, width: 12 }} />
      <span 
        style={{ cursor: 'pointer', fontSize: 11, fontWeight: '500', color: 'black', textAlign: 'center'}}>
          Go Back
        </span>
    </div>
    </>
  )
}

export default AcceptForm