/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';
import LOGO from '../assets/main-logo.png';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`

const ToolBar = styled.div`
  width: 100%;
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 12px;

  img {
    height: 54px;
    width: 86px;
    object-fit: contain;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow-y: scroll;

`

const PublicFormLayout = ({
  children,
}) => {
  return (
    <Wrapper>
      <ToolBar> 
        <Link to='/signin'>
          <img src={LOGO} />
        </Link>
      </ToolBar>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default PublicFormLayout