import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Wrapper, ReviewOption } from './styles';

const Indicator = styled.div`
  height: 10px;
  width: 10px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px; 
  background-color: rgba(51,122,183, .2);
  border: 1px solid rgba(51,122,183, .4);
  
  div {
    height: 4px;
    width: 4px; 
    border-radius: 50%;
    background-color: rgb(51, 122, 183);
  }
`;

const IndicatorCorrect = styled(Indicator)`
  background-color: rgba(146, 255, 146, 0.18);
  border: 1px solid mediumseagreen;

  div {
    border-radius: 50%;
    background-color: mediumseagreen;
  }
`;

const HoldIndicators = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
`;

const TrueFalse = ({
  question,
  userAnswer,
}) => {
  const parsedAnswer = JSON.parse(userAnswer).toLowerCase();

  return (
    <Wrapper>
      <ReviewOption>
        <span>True</span>
        <HoldIndicators>
          {
            parsedAnswer === 'true' ? (
              <Indicator>
                <div />
              </Indicator>
            ) : (
              <div />
            )
          }
          {
            question.correctAnswer.toLowerCase() === 'true' ? (
              <IndicatorCorrect>
                <div />
              </IndicatorCorrect>
            ) : (
              <div />
            )
          }
        </HoldIndicators>
      </ReviewOption>
      <ReviewOption>
        <span>False</span>
        <HoldIndicators>
          {
            parsedAnswer === 'false' ? (
              <Indicator>
                <div />
              </Indicator>
            ) : (
              <div />
            )
          }
          {
            question.correctAnswer.toLowerCase() === 'false' ? (
              <IndicatorCorrect>
                <div />
              </IndicatorCorrect>
            ) : (
              <div />
            )
          }
        </HoldIndicators>
      </ReviewOption>
    </Wrapper>
  );
};

export default TrueFalse;

TrueFalse.propTypes = {
  question: PropTypes.object.isRequired,
  userAnswer: PropTypes.string.isRequired,
};
