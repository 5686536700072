/* eslint-disable */
import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import styled from 'styled-components/macro';
import useSnack from '../store/snack';
import { ThumbsUp, ThumbsDown, AlertTriangle } from 'react-feather';

const CustomSnack = styled(Snackbar)`
  .MuiPaper-root{
    background-color: white;  
    color: ${props => props.theme.palette.background.bw};
    border: 1px solid lightgray;
  }
`;


const getIcon = (snackType) => {

  if (!snackType) return null;

  if (snackType === 'success') {
    return <ThumbsUp />
  }

  if (snackType === 'warning') {
    return <AlertTriangle />
  }

  if (snackType === 'error') {
    return <ThumbsDown />
  }

  return;

}

const GlobalSnack = () => {

  const { snack } = useSnack();
  const { snackText, closeSnack, snackType } = snack;

  return(
    <CustomSnack 
      autoHideDuration={6000} 
      open={Boolean(snackText)} 
      onClose={() => closeSnack()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      {
        snackText && (
          <Alert icon={getIcon(snackType)} severity={snackType}>
            {snackText}
          </Alert>
        )
      }
    </CustomSnack>
  ) 
  
  
};

export default GlobalSnack;

