/* eslint-disable */
/* eslint-disable camelcase */
import React, { useState, useContext, useRef } from "react";
import { Typography, IconButton, Avatar, Modal, Slide } from "@mui/material";
import styled from "styled-components/macro";
import { ChevronDown, ChevronUp } from "react-feather";
import useUserData from "../../../reactQueryHooks/useUserData";
import ProfileOpen from "./ProfileOpen";
import UploadProfilePhoto from "../../Modals/UploadProfilePhotoModal";
import ReuseablePopper from "../../Reuseable/ReuseablePopper";
import { GlobalState } from "../../../store/global";
import useSnack from "../../../store/snack";
import EditUserInfoModal from "../../Modals/EditUserInfoModal";

const NameText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};

  @media (max-width: 460px) {
    display: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  svg {
    color: ${(props) => props.theme.palette.background.bw};
  }
`;

const CustomAvatar = styled(Avatar)`
  height: 34px;
  width: 34px;
  font-size: 16px;
`;

const AvatarWrapper = styled.div`
  margin-right: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const SlidingEdit = styled.div`
  height: 62%;
  width: 110%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: -3px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }

  p {
    font-size: 10px;
    color: white;

    &:hover {
      color: dodgerblue;
      cursor: pointer;
    }
  }
`;

const UserProfile = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const {
    showUploadModal,
    setShowUploadModal,
    showEditInfoModal,
    setShowEditInfoModal,
  } = useContext(GlobalState);
  const { isLoading, error, data, refetch } = useUserData();
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;

  if (isLoading) return null;

  if (error) return null;

  const { profile } = data;

  const allInfoUpToDate =
    profile.first_name &&
    profile.last_name &&
    profile.email &&
    profile.country &&
    profile.state &&
    profile.birth_date;

  const { first_name, last_name, photo } = profile;

  const initials = `${first_name.trim()[0]}${last_name.trim()[0]}`;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleExitModal = () => {
    if (allInfoUpToDate) {
      setShowEditInfoModal(false);
    } else {
      setSnack(
        "Must fill mandatory information before accessing app",
        SNACK_TYPES.WARNING
      );
    }
  };

  return (
    <>
      {/* <DividerLeft /> */}
      <AvatarWrapper
        ref={containerRef}
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <CustomAvatar size="small" src={photo}>
          {initials}
        </CustomAvatar>
        <Slide direction="up" in={show} container={containerRef.current}>
          <SlidingEdit onClick={() => setShowUploadModal(true)}>
            <p>{photo ? "Edit" : "Add"}</p>
          </SlidingEdit>
        </Slide>
      </AvatarWrapper>
      <NameText
        variant="h6"
        sx={{
          mr: 1,
        }}
      >
        {`${first_name} ${last_name}`}
      </NameText>
      <StyledIconButton
        style={{ border: "1px solid #EEEEEE66" }}
        edge="end"
        onClick={handleClick}
      >
        {anchorEl ? <ChevronUp /> : <ChevronDown />}
      </StyledIconButton>
      <ReuseablePopper anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <ProfileOpen data={data} />
      </ReuseablePopper>
      <Modal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        aria-labelledby="modal-edit-profile-picture"
        aria-describedby="modal-edit-your-profile-picture"
      >
        <div>
          <UploadProfilePhoto setShowUploadModal={setShowUploadModal} />
        </div>
      </Modal>
      <Modal
        open={showEditInfoModal}
        onClose={handleExitModal}
        aria-labelledby="modal-edit-info"
        aria-describedby="modal-edit-personal-information"
      >
        <div>
          <EditUserInfoModal
            profile={profile}
            refetch={refetch}
            handleExitModal={handleExitModal}
          />
        </div>
      </Modal>
    </>
  );
};

export default UserProfile;
