/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  CircularProgress,
} from '@mui/material';
import API from '../../../axios/API';
import { CustomTextField } from '../../../styles/forms';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Email is required')
    .min(2, 'Must be at least 2 characters'),
  firstName: Yup.string()
    .required('First name is required')
    .min(2, 'Must be at least 2 characters'),
  lastName: Yup.string()
    .required('Last name is required'),
});

const BasicsForm = ({
  profile,
  setSnack,
  SNACK_TYPES,
  refetch,
  setExpandedIndex,
}) => {
  const InitialValues = {
    email: profile.email,
    firstName: profile.first_name,
    lastName: profile.last_name,
  };

  const handleFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await API.patch('accounts/profile/', {
        last_name: values.lastName,
        first_name: values.firstName,
        email: values.email,
      });
      await refetch();
      setSubmitting(false);
      setExpandedIndex(null)
      setSnack('Successfully updated your information', SNACK_TYPES.SUCCESS);
    } catch (error) {
      setSubmitting(false);
      setSnack('Failed to update your information', SNACK_TYPES.ERROR);
    }
  };

  return (

    <Formik
      initialValues={InitialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        errors,
        values,
        touched,
        handleChange,
        handleBlur,
        dirty,
        isSubmitting,
        handleSubmit,
      }) => (
        <form noValidate onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomTextField
            fullWidth
            variant="outlined"
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            value={values.firstName}
            name="firstName"
            label="First Name"
            sx={{
              mb: 4,
            }}
            size="small"
          />
          <CustomTextField
            fullWidth
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            variant="outlined"
            label="Last Name"
            sx={{
              mb: 4,
            }}
            size="small"
          />
          <CustomTextField
            fullWidth
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            variant="outlined"
            label="Email"
            sx={{
              mb: 4,
            }}
            size="small"
          />
          <Button
            type="submit"
            size="small"
            variant="contained"
            color="primary"
            disabled={!dirty}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            sx={{
              marginTop: 'auto',
              marginLeft: 'auto',
            }}
          >
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default BasicsForm;

BasicsForm.propTypes = {
  profile: PropTypes.object.isRequired,
  setSnack: PropTypes.func.isRequired,
  SNACK_TYPES: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
