/* eslint-disable */
import { useQuery } from 'react-query';
import API from '../axios/API';

const getVerticals = async () => {
  const { data } = await API.get('dashboard/signup/company/verticals/');
  return data.verticals //array
};

const useAllVerticals = () => useQuery('verticals', getVerticals);

export default useAllVerticals;
