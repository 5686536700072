/* eslint-disable */
import React from "react";
import ModuleCardV3 from "./ModuleCardV3";
import { ArrowRight, Flag } from "react-feather";
import RightButton from "./RightButton";
import LeftButton from "./LeftButton";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import DisplayText from "./DisplayText";

const DisplayStreamName = ({ name, companyName }) => (
  <div
    style={{
      borderRadius: 4,
      flexGrow: 0,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "fit-content",
      flexDirection: "column",
      paddingLeft: 8,
      padding: "4px",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Flag
        color="black"
        height={20}
        width={20}
        style={{ padding: 4, borderRadius: 10, background: "#eeeeee" }}
      />
      <span style={{ fontSize: 14, color: "darkgrey", fontWeight: "500" }}>
        Stream from {companyName} /
      </span>
      <span
        style={{
          fontWeight: "500",
          whiteSpace: "nowrap",
          fontSize: 14,
          color: "black",
          maxWidth: 280,
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingLeft: 4,
        }}
      >
        {name}
      </span>
    </div>
  </div>
);

const DisplayStreamsV3 = ({
  stream,
  navigate,
  getNextModuleInfo,
  companyName,
}) => {
  return (
    <div
      style={{
        width: "100%",
        padding: "10px 0px",
        marginBottom: 24,

        padding: 12,
        // borderRadius: 8,
        borderBottom: "1px solid #eeeeee",
      }}
    >
      <ScrollMenu
        Header={
          <DisplayStreamName name={stream.name} companyName={companyName} />
        }
        Footer={
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            <LeftButton />

            <DisplayText numModules={stream?.quizzes?.length} />
            <RightButton />
          </div>
        }
      >
        {stream.quizzes.map((item) => (
          <ModuleCardV3
            key={item.id}
            itemId={item.id}
            mod={item}
            navigate={navigate}
            getNextModuleInfo={getNextModuleInfo}
          />
        ))}
      </ScrollMenu>
    </div>
  );
};

export default DisplayStreamsV3;
