/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player/vimeo";
import styled from "styled-components/macro";
import { CircularProgress, Typography } from "@mui/material";
import { fadeInOut } from "../../styles/keyframes";

const Wrapper = styled.div`
  // padding: 0px 10px;
  position: relative;
  width: content-fit;
  // background: ${(props) => props.theme.palette.background.contrast};
  border-radius: 4px;
  height: auto;

  .vp-center {
    height: auto;
  }
`;

const LoadingWrapper = styled.div`
  width: ${(props) => props.width}px;
  height: 100%;
  position: absolute;
  left; 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
  z-index: 1000;
`;

const LoadingText = styled(Typography)`
  font-size: 14px;
  font-style: italic;
  animation: 1.8s ${fadeInOut} ease-out infinite;
  color: white;
`;

const getCleanVideoId = (idString) => {
  if (idString.includes("/video")) {
    const numbersOnly = idString.split("/")[2];
    return numbersOnly;
  }

  return idString;
};

const ShowVideo = ({ videoId, width, height }) => {
  const [startPlaying, setStartPlaying] = useState(false);
  const [visible, setIsVisible] = useState(false);

  const cleanVideoId = getCleanVideoId(videoId);

  return (
    <Wrapper>
      {!visible && (
        <LoadingWrapper height={height} width={width}>
          <LoadingText sx={{ mb: 4 }}>loading video...</LoadingText>
          <CircularProgress size={50} style={{ color: "white" }} />
        </LoadingWrapper>
      )}
      <ReactPlayer
        url={`https://vimeo.com/${cleanVideoId}`}
        controls
        playing={startPlaying}
        onReady={() => {
          setStartPlaying(true);
          setTimeout(() => {
            setIsVisible(true);
          }, [200]);
        }}
        width={width}
        height={height}
      />
    </Wrapper>
  );
};

export default ShowVideo;

ShowVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};
