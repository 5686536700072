/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const DisplayModuleTypeChip = ({ isAssigned = true }) => {
  if (isAssigned) {
    return (
      <Container>
        <div
          style={{
            height: 24,
            width: 24,
            borderRadius: 6,
            // background: "rgba(0, 0, 150, .1)",
            background: "white",
            border: "1px solid rgb(88, 125, 234)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontWeight: "700", color: "rgb(88, 125, 234)" }}>
            A
          </span>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div
        style={{
          height: 24,
          width: 24,
          borderRadius: 6,
          background: "rgb(255, 238, 176)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid rgb(170, 151, 0)",
        }}
      >
        <span style={{ fontWeight: "700", color: "rgb(170, 151, 0)" }}>E</span>
      </div>
    </Container>
  );
};

export default DisplayModuleTypeChip;
