import React from 'react';
import styled from 'styled-components/macro';
import { LinearProgress } from '@mui/material';

const CustomLinearProg = styled(LinearProgress)`
  position: absolute;
  bottom: 0px;
  left: 2%;
  width: 96%;
  height: 2px;
  background-color: transparent;
`;

const LinearProgLoader = () => <CustomLinearProg variant="indeterminate" color="secondary" />;

export default LinearProgLoader;
