/* eslint-disable */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  Button,
  Checkbox,
  Typography,
  Autocomplete,
  Select,
  FormControl,
  FormControlLabel,
  MenuItem,
  InputLabel,
  Tooltip,
} from "@mui/material";
import { CheckCircle, AlertCircle, X } from "react-feather";
import LinearProgLoader from "../../../components/Loaders/LinearProgLoader";
import API from "../../../axios/API";
import { CustomTextField } from "../../../styles/forms";
import useAuth from "../../../store/auth";
import { GlobalState } from "../../../store/global";
import FormThree from "./FormThree";
import { lightFormat } from "date-fns";

const FormTwoWrapper = styled.div`
  min-height: 360px;
  display: flex;
  flex-direction: column;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 12px;

  .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
    margin-right: 0px;
    transform: translate(0px, 0px);
  }

  svg {
    height: 14px;
    width: 14px;
    margin-right: 4px;
    transform: translate(3px, -1px);
  }

  a {
    color: ${(props) => props.theme.palette.info.dark};
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const StyledButton = styled(Button)`
  border: 1px solid black;
  color: black;
`;

const StyledSelect = styled(Select)`
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.background.bw} !important;
  }
`;

const AlertCircleBlue = styled(AlertCircle)`
  color: ${(props) => props.theme.palette.info.light};
`;

const CheckCircleGreen = styled(CheckCircle)`
  color: ${(props) => props.theme.palette.success.light};
`;

const ErrorSpan = styled.span`
  font-size: 11px;
  color: tomato;
  margin-left: auto;
  margin-right: auto;
  margin-top: -12px;

  svg {
    height: 14px;
    width: 14px;
    margin-left: 6px;
    transform: translate(0px, 4px);
    cursor: pointer;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${(props) => props.theme.palette.background.bw};
`;

const TextSpan = styled.span`
  color: ${(props) => props.theme.palette.background.bw};
`;

const StyledAutocomplete = styled(Autocomplete)`
  * {
    color: ${(props) => props.theme.palette.background.bw};
  }
`;

const SPECIAL_ID = "NO TEAM";

const formatDateForApi = (date) => lightFormat(date, "yyyy-MM-dd");

const FormTwo = ({ dispatch, formState, ACTIONS, snack }) => {
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [availableLocations, setAvailableLocations] = useState(null);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [errorText, setErrorText] = useState(false);

  const [roleSelectOpen, setRoleSelectOpen] = useState(false);

  const { setSnack, SNACK_TYPES } = snack;
  const { login } = useAuth();
  const { setShowRegisterModal } = useContext(GlobalState);

  const {
    selectedCompany,
    selectedLocation,
    agreedToTerms,
    email,
    firstName,
    lastName,
    password,
    dob,
    ref,
    selectedRoles,
    hasTeam,
    newSelectedCountry,
    newSelectedIndustry,
    newSelectedState,
    newCompanyText,
  } = formState;

  useEffect(() => {
    if (search.length < 3) {
      setOptions([]);
      return undefined;
    }

    const searchCompany = async () => {
      try {
        const { data } = await API.get(
          `dashboard/signup/company/search/?search=${search}`
        );
        setOptions([
          ...data.companies,
          { id: SPECIAL_ID, name: "My Team Is Not Listed" },
        ]);
      } catch (error) {
        setErrorText("Error loading companies. Please try again.");
      }
    };

    searchCompany();
  }, [search]);

  useEffect(() => {
    if (!selectedCompany) return undefined;

    const getRolesAndLocations = async () => {
      try {
        const { data } = await API.get(
          `dashboard/signup/company/locations/?company_id=${selectedCompany.id}`
        );
        const { data: dataTwo } = await API.get(
          `dashboard/signup/company/roles/?company_id=${selectedCompany.id}`
        );
        setAvailableLocations(data.locations);
        setAvailableRoles(dataTwo.roles.map((r) => ({ id: r[0], name: r[1] })));
      } catch (error) {
        setErrorText("Error loading location data. Please try again.");
      }
    };

    getRolesAndLocations();
  }, [selectedCompany]);

  const handleSubmit = async () => {
    setSubmitting(true);

    let submitObj;

    if (hasTeam) {
      submitObj = {
        version: 2,
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        birth_date: formatDateForApi(dob),
        referral_code: ref,
        has_company: true,
        location_id: selectedLocation.id,
        roles: selectedRoles.map((r) => r.id),
      };
    } else {
      submitObj = {
        version: 2,
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        birth_date: formatDateForApi(dob),
        referral_code: ref,
        has_company: false,
        country_id: newSelectedCountry,
        state_id: newSelectedState,
        searched_company: newCompanyText || "",
      };
    }

    try {
      const { data } = await API.post("/accounts/signup/", submitObj);
      setSubmitting(false);
      // setSnack('You were successfully registered, welcome to spiffy!!', SNACK_TYPES.SUCCESS);
      setShowRegisterModal(true);
      return login(data.token);
    } catch (error) {
      setSubmitting(false);
      if (
        error.response &&
        error.response.data &&
        error.response.data === "email_unavailable"
      ) {
        return setSnack(
          "Failed to sign you up, there is already an account with this email",
          SNACK_TYPES.ERROR
        );
      }
      return setSnack(
        "Failed to sign you up, please try again",
        SNACK_TYPES.ERROR
      );
    }
  };

  const handleRoleSelectClose = () => setRoleSelectOpen(false);
  const handleRoleSelectOpen = () => setRoleSelectOpen(true);

  const shouldFinishBeDisabled = () => {
    /// if terms and conditions not accepted.. disable button
    if (!agreedToTerms) return true;

    if (hasTeam) {
      if (!selectedCompany || !selectedLocation || selectedRoles.length < 1) {
        return true;
      }
      return false;
    }

    if (!hasTeam) {
      if (!newSelectedState || !newSelectedCountry) {
        return true;
      }
      return false;
    }
  };

  return (
    <FormTwoWrapper>
      <FlexDiv style={{ marginTop: 4 }}>
        <Typography variant="subtitle2">
          Search for your company here
        </Typography>
        {!selectedCompany && search.length <= 3 && hasTeam && <AlertCircle />}
        {!selectedCompany && search.length > 3 && hasTeam && (
          <AlertCircleBlue />
        )}
        {selectedCompany && hasTeam && <CheckCircleGreen />}
      </FlexDiv>
      <StyledAutocomplete
        filterOptions={(x) => x}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setOptions([]);
        }}
        isOptionEqualToValue={(option, value) => option.name == value.name}
        onInputChange={(e) => {
          return e.target.innerText
            ? setSearch(e.target.innerText)
            : setSearch("");
        }}
        onChange={(_, v) => {
          if (v && v.id === SPECIAL_ID) {
            return dispatch({ type: ACTIONS.TOGGLE_HAS_TEAM });
          }
          dispatch({ type: ACTIONS.SET_COMPANY, payload: v });
        }}
        getOptionLabel={(option) => option.name}
        options={options}
        noOptionsText="No Companies Found"
        renderInput={(params) => (
          <CustomTextField
            {...params}
            variant="outlined"
            size="small"
            label="Search"
            fullWidth
            placeholder="Type To Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
      />
      {selectedCompany && availableLocations && hasTeam && (
        <>
          <FlexDiv>
            <Typography variant="subtitle2">Location</Typography>
            {!selectedLocation && <AlertCircle />}
            {selectedLocation && <CheckCircleGreen />}
          </FlexDiv>
          <CustomTextField
            select
            label="Select the location you work at"
            fullWidth
            variant="outlined"
            size="small"
            color="secondary"
            placeholder="Select a location.."
            value={selectedLocation}
            onChange={(e) =>
              dispatch({ type: ACTIONS.SET_LOCATION, payload: e.target.value })
            }
          >
            {availableLocations.map((loc) => (
              <MenuItem value={loc} key={loc.id}>
                {loc.name}
              </MenuItem>
            ))}
          </CustomTextField>
        </>
      )}
      {selectedCompany && availableRoles.length > 0 && hasTeam && (
        <>
          <FlexDiv>
            <Typography variant="subtitle2">Role</Typography>
            {selectedRoles.length === 0 && <AlertCircle />}
            {selectedRoles.length > 0 && <CheckCircleGreen />}
          </FlexDiv>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" size="small">
              Roles
            </InputLabel>
            <StyledSelect
              labelId="demo-simple-select-label"
              select
              multiple
              open={roleSelectOpen}
              onClose={handleRoleSelectClose}
              onOpen={handleRoleSelectOpen}
              label="Roles"
              fullWidth
              variant="outlined"
              size="small"
              color="secondary"
              value={selectedRoles}
              onChange={(e) => {
                dispatch({ type: ACTIONS.SET_ROLES, payload: e.target.value });
                return setRoleSelectOpen(false);
              }}
            >
              {availableRoles.map((role) => (
                <MenuItem value={role} key={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </StyledSelect>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div style={{ padding: 2 }}>
                <Tooltip
                  arrow
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#EEEEEE",
                        border: "1px solid gainsboro",
                      },
                    },
                  }}
                  title={
                    <div>
                      <p style={{ color: "#01579b", fontWeight: "500" }}>
                        What are roles?
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontWeight: "300",
                          lineHeight: 1.2,
                        }}
                      >
                        Certain modules are deployed to specific roles based on
                        job functions. Roles are used to identify what content
                        is displayed to you in your app feed.
                      </p>
                    </div>
                  }
                >
                  <span
                    style={{
                      fontSize: 11,
                      color: "#01579b",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    What are roles?
                  </span>
                </Tooltip>
              </div>
            </div>
          </FormControl>
        </>
      )}
      {!hasTeam && (
        <FormThree
          dispatch={dispatch}
          ACTIONS={ACTIONS}
          formState={formState}
        />
      )}
      <FlexDiv style={{ marginTop: "auto", paddingTop: 8 }}>
        <Checkbox
          checked={agreedToTerms}
          onClick={(e) =>
            dispatch({ type: ACTIONS.TOGGLE_AGREED, payload: e.target.checked })
          }
          size="small"
          color="primary"
        />
        <TextSpan style={{ marginLeft: 4 }}>
          I agree to the{" "}
          <a
            href="https://www.withspiffy.com/terms-and-conditions/"
            target="_blank"
          >
            terms and conditions
          </a>
        </TextSpan>
        {agreedToTerms ? <CheckCircleGreen /> : <AlertCircle />}
      </FlexDiv>
      {errorText && (
        <ErrorSpan>
          {errorText}
          <X onClick={() => setErrorText(false)} />
        </ErrorSpan>
      )}
      <StyledButton
        type="submit"
        variant="outlined"
        color="primary"
        fullWidth
        sx={{
          p: 1,
          fontSize: 14,
          mb: 2,
          marginTop: 2,
        }}
        onClick={handleSubmit}
        disabled={shouldFinishBeDisabled()}
      >
        Finish
        {submitting && <LinearProgLoader />}
      </StyledButton>
    </FormTwoWrapper>
  );
};

export default FormTwo;

FormTwo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  ACTIONS: PropTypes.object.isRequired,
  snack: PropTypes.object.isRequired,
};
