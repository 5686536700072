/* eslint-disable */
import React from "react";
import CRASH from "../assets/crash.png";
import styled from "styled-components/macro";
import { Button } from "@mui/material";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 420px;
  width: 420px;
  border-radius: 12px;
  background: white;
  padding: 16px;
  border: 1px solid lightgray;
`;

const ErrorBoundaryUI = () => {
  return (
    <Wrapper>
      <Container>
        <p style={{ fontSize: 16, fontWeight: "600" }}>Sorry, we crashed.</p>
        <p style={{ fontSize: 13, color: "darkgrey" }}>
          A report has been sent and our engineers are looking into it.
        </p>
        <div
          style={{
            width: "100%",
            height: "auto",
            flex: 1,

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img
            style={{ height: 210, width: 210, objectFit: "contain" }}
            src={CRASH}
          />
        </div>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ marginLeft: "auto" }}
          onClick={() => window.location.reload()}
        >
          Reload
        </Button>
      </Container>
    </Wrapper>
  );
};

export default ErrorBoundaryUI;
