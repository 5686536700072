/* eslint-disable */

import React from "react";
import {
  InputAdornment,
  MenuItem,
  InputLabel,
  FormControl,
  Tooltip,
} from "@mui/material";
import { Tag } from "react-feather";
import { CustomSelectField } from "../../../styles/forms";

const RoleSelect = ({
  roles,
  touched,
  handleChange,
  handleBlur,
  errors,
  values,
  disabled,
}) => {
  return (
    <>
      <FormControl style={{ width: "100%" }} size="small">
        <InputLabel id="role-at-company">Role At Company</InputLabel>
        <CustomSelectField
          multiple
          label="Role At Company"
          labelId="role-at-company"
          name="roles"
          variant="outlined"
          value={values.roles}
          color="secondary"
          onBlur={handleBlur}
          disabled={disabled}
          error={Boolean(touched.roles && errors.roles)}
          onChange={handleChange}
          helperText={touched.roles && errors.roles}
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tag style={{ color: disabled ? "darkgrey" : "black" }} />
              </InputAdornment>
            ),
          }}
        >
          {roles.map((role) => (
            <MenuItem key={role.name} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </CustomSelectField>
      </FormControl>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 8,
          // border: "1px solid red",
        }}
      >
        <div style={{ padding: 2 }}>
          <Tooltip
            arrow
            PopperProps={{
              sx: {
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "#eeeeee",
                  border: "1px solid gainsboro",
                },
              },
            }}
            title={
              <div>
                <p
                  style={{
                    color: "black",
                    // color: 'darkgray',
                    fontWeight: "500",
                  }}
                >
                  What are roles?
                </p>
                <p
                  style={{ color: "black", fontWeight: "400", lineHeight: 1.2 }}
                >
                  Roles represent job functions. The content you recieve will
                  depend on the roles you select. Dont worry, these can be
                  changed later time if needed.
                </p>
              </div>
            }
          >
            <span
              style={{
                fontSize: 11,
                color: "#01579b",
                fontWeight: "500",
                cursor: "pointer",
              }}
            >
              What are roles?
            </span>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default RoleSelect;
