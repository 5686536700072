import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Wrapper, ReviewOption } from './styles';

const Indicator = styled.div`
  height: 10px;
  width: 10px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px; 
  background-color: rgba(51,122,183, .2);
  border: 1px solid rgba(51,122,183, .4);
  
  div {
    height: 4px;
    width: 4px; 
    border-radius: 50%;
    background-color: rgb(51, 122, 183);
  }
`;

const IndicatorCorrect = styled(Indicator)`
  background-color: rgba(146, 255, 146, 0.18);
  border: 1px solid mediumseagreen;

  div {
    border-radius: 50%;
    background-color: mediumseagreen;
  }
`;

const HoldIndicators = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
`;

const MultipleChoice = ({
  question,
  userAnswer,
}) => {
  const parsedAnswer = JSON.parse(userAnswer).toUpperCase();

  return (
    <Wrapper>
      {Object.entries(question.options).map(option => (
        <ReviewOption
          key={option[0]}
          selected={parsedAnswer === option[0].toUpperCase() ? 'yes' : 'no'}
          wasCorrect={question.correctAnswer.toUpperCase() === parsedAnswer ? 'yes' : 'no'}
        >
          <span>
            {`${option[0].toUpperCase()})  ${option[1]}`}
          </span>
          <HoldIndicators>
            {
              parsedAnswer === option[0].toUpperCase()
                ? <Indicator><div /></Indicator>
                : <div />
            }
            {
              question.correctAnswer
                .toUpperCase() === option[0].toUpperCase()
                ? <IndicatorCorrect><div /></IndicatorCorrect>
                : <div />
            }
          </HoldIndicators>
        </ReviewOption>
      ))}
    </Wrapper>
  );
};

export default MultipleChoice;

MultipleChoice.propTypes = {
  question: PropTypes.object.isRequired,
  userAnswer: PropTypes.string.isRequired,
};
