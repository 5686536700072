/* eslint-disable import/prefer-default-export */
export const ROW_TYPES = {
  ASSIGNED_STREAM: 'ASSIGNED_STREAM',
  ASSIGNED_CUSTOM_MODULE: 'ASSIGNED CUSTOM MODULE',
  ASSIGNED_MP_MODULE: 'ASSIGNED MP MODULE',
  ASSIGNED_FOLLOWER_STREAM: 'ASSIGNED FOLLOWER STREAM',
  FOLLOWER_MODULE: 'FOLLOWER MODULE',
  FOLLOWER_STREAM: 'FOLLOWER STREAM',
};

export const STREAM_TYPES_ARR = [
  ROW_TYPES.ASSIGNED_STREAM,
  ROW_TYPES.FOLLOWER_STREAM,
  ROW_TYPES.ASSIGNED_FOLLOWER_STREAM,
];

export const ASSIGNED_TYPES_ARR = [
  ROW_TYPES.ASSIGNED_STREAM,
  ROW_TYPES.ASSIGNED_CUSTOM_MODULE,
  ROW_TYPES.ASSIGNED_MP_MODULE,
  ROW_TYPES.ASSIGNED_FOLLOWER_STREAM,
];

export const LEADERBOARD_TYPES = {
  LOCATION: 'location',
  COMPANY: 'company',
  INDUSTRY: 'vertical',
};

export const ASSIGNED_STREAMS_ARR = [ROW_TYPES.ASSIGNED_STREAM, ROW_TYPES.ASSIGNED_FOLLOWER_STREAM];
