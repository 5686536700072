/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { addLogoString } from '../../../helpers';

const Wrapper = styled.div`
  width: 100%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #EEEEEE88;
  border-radius: 4px;
  margin-bottom: 4px;

`;

const BadgeWrapper = styled.div`
  background-color: white; 
  padding: 10px;
  border-radius: 14px;
  box-shadow: 1px 1px 4px 3px #EEEEEE;
  border: 1px solid #EEEEEE;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
`;

const LevelDiv = styled.div`
  background-color: rgb(51, 122, 183);
  border-radius: 8px;
  padding: 1px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: white;
    font-size: 12px;
    font-weight: 500;
  }
`;

const BadgeImage = styled.img`
  height: 90px;
  width: 90px;
  max-height: 90px;
  max-width: 90px;
  border-radius: 45px;
  object-fit: contain;
`;

const ShowBadge = ({
  badge,
  nextLevels,
}) => {
  const nextLevel = nextLevels[badge.achievement_code];

  return (
    <Wrapper>
      <BadgeWrapper>
        <BadgeImage src={addLogoString(badge.achievement_level_image)} />
      </BadgeWrapper>
      <div
        style={{
          flex: 1,
          marginLeft: 12,
          height: 110,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <span style={{ fontSize: 20, fontWeight: 500 }}>
          {badge.achievement_name}
        </span>
        <span style={{ color: 'darkgrey', marginTop: -2 }}>
          {badge.achievement_level_descriptions.short_description.english}
        </span>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
            marginTop: 6,
            flexDirection: 'column',
          }}
        >
          <LevelDiv>
            <span>Level: {badge.achievement_level_level} achieved</span>
          </LevelDiv>
          {
            nextLevel ? (
              <div
                style={{
                  padding: '1px 4px',
                  background: '#EEEEEE88',
                  borderRadius: 4,
                  marginTop: 4,
                }}
              >
                <span style={{ fontSize: 10, fontWeight: 300, fontStyle: 'italic' }}>
                  <strong>Level {nextLevel.level}:</strong> {nextLevel.descriptions.short_description.english}
                </span>
              </div>
            ) : <div />
          }

        </div>
      </div>
    </Wrapper>
  );
};

export default ShowBadge;

ShowBadge.propTypes = {
  badge: PropTypes.object.isRequired,
  nextLevels: PropTypes.object.isRequired,
};
