import { useQuery } from 'react-query';
import API from '../axios/API';

const getStatesByCountry = async countryCode => {
  const { data } = await API.get(`dashboard/address/states/?country_code=${countryCode}`);
  return data.states;
};

const useStatesByCountry = countryCode => useQuery(['states', countryCode], () => getStatesByCountry(countryCode));

export default useStatesByCountry;
