/* eslint-disable */
import React from 'react';
import styled from 'styled-components/macro';
import { Outlet, Navigate } from 'react-router-dom'
import useAuth from '../store/auth'


const Root = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.palette.background.main};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Auth = () => {

  const auth = useAuth();
  const { authenticated } = auth

  if (authenticated) {

    return (
      <Navigate to="/"/>
    )
  }

  return(
    <Root>
      <Outlet />
    </Root>
  )
};

export default Auth;

