import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button, CircularProgress } from '@mui/material';
import Confetti from 'react-confetti';
import API from '../../../axios/API';
import useSnack from '../../../store/snack';
import ShowBadge from './ShowBadge';

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  padding: 20px;
  min-height: 200px;
  width: 520px;
  background: white;
  border-radius: 4px;
`;

const NewBadgeModal = ({
  data,
  setNewBadgeData,
}) => {
  const { snack } = useSnack();
  const { setSnack, SNACK_TYPES } = snack;
  const [claiming, setClaiming] = useState(false);

  const markBadgesAsViewed = async () => {
    setClaiming(true);
    try {
      await API.put('/achievements/new-badges/', {
        viewed_badges: data.new_badges.map(badge => badge.id),
      });
      setClaiming(false);
      setSnack('Badges claimed. You can view badges in your profile');
      setNewBadgeData(null);
    } catch (error) {
      setClaiming(false);
      setSnack('Error claiming your badges. You can view badges in your profile', SNACK_TYPES.ERROR);
      setNewBadgeData(null);
    }
  };

  if (!data) return <Wrapper><span>loading...</span></Wrapper>;

  return (
    <>
      <Confetti
        recycle={false}
        tweenDuration={4000}
        numberOfPieces={400}
      />
      <Wrapper>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            marginBottom: 18,
            marginLeft: 4,
            backgroundColor: 'aliceblue',
            color: 'black',
            padding: 6,
          }}
        >
          You Earned {data.new_badges.length === 1 ? 'A Badge' : `${data.new_badges.length} New Badges` } 🎉🎉
        </p>
        <div style={{ width: '100%', minHeight: 120 }}>
          {
            data.new_badges.map(badge => (
              <ShowBadge
                badge={badge}
                key={badge.achievement_name}
                nextLevels={data.next_levels}
              />
            ))
          }
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 12,
          }}
        >
          <div />
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={claiming}
            onClick={markBadgesAsViewed}
            endIcon={claiming ? <CircularProgress size={20} /> : null}
          >
            Claim {data.new_badges.length === 1 ? 'Badge' : 'Badges'}
          </Button>
        </div>
      </Wrapper>
    </>
  );
};

export default NewBadgeModal;

NewBadgeModal.propTypes = {
  data: PropTypes.object,
  setNewBadgeData: PropTypes.func.isRequired,
};

NewBadgeModal.defaultProps = {
  data: null,
};
