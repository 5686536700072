/* eslint-disable */
import styled from "styled-components/macro";
import { TextField, Button, Select } from "@mui/material";

export const CustomTextField = styled(TextField)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background.paper};

  label {
    // font-weight: 300;
  }

  input {
    color: ${(props) => props.theme.palette.background.bg};
    padding: 9.5px 14px;
  }

  & label.Mui-focused {
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 400;
  }

  .MuiSvgIcon-root {
    height: 1em;
    width: 1em;
    color: ${(props) => props.theme.palette.background.bw} !important;
  }

  svg {
    color: ${(props) => props.theme.palette.background.bw};
    height: 14px;
    width: 14px;
  }

  .MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
  }

  .MuiFormHelperText-root {
    font-size: 10px;
    margin-top: 0px;
    color: ${(props) => props.theme.palette.background.bw};
  }
`;

export const CustomSelectField = styled(Select)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background.paper};

  label {
    // font-weight: 300;
  }

  input {
    color: ${(props) => props.theme.palette.background.bg};
    padding: 9.5px 14px;
  }

  & label.Mui-focused {
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 400;
  }

  .MuiSvgIcon-root {
    height: 1em;
    width: 1em;
    color: ${(props) => props.theme.palette.background.bw} !important;
  }

  svg {
    color: ${(props) => props.theme.palette.background.bw};
    height: 14px;
    width: 14px;
  }

  .MuiFormHelperText-root.Mui-error {
    color: #d32f2f;
  }

  .MuiFormHelperText-root {
    font-size: 10px;
    margin-top: 0px;
    color: ${(props) => props.theme.palette.background.bw};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: auto;
  color: ${(props) => props.theme.palette.background.bw};
  border: 1px solid ${(props) => props.theme.palette.background.bw};
`;
