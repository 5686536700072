/* eslint-disable */
import React from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

const Root = styled.div`
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.83);
  display: flex;
`;

const ModuleLayout = () => {
  return (
    <Root>
      <Outlet />
    </Root>
  );
};

export default ModuleLayout;
