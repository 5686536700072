/* eslint-disable */
import React, { useContext } from "react";
import { ArrowRight } from "react-feather";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { Button } from "@mui/material";

const RightButton = () => {
  const visibility = useContext(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", true);

  return (
    <Button
      disabled={isLastItemVisible}
      style={{
        height: 33,
        width: 33,
        maxWidth: 36,
        minWidth: 0,
        padding: 0,
        borderRadius: 8,
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid lightgray",
      }}
      onClick={() => visibility.scrollNext()}
    >
      <ArrowRight
        color={isLastItemVisible ? "darkgrey" : "black"}
        height={18}
        width={18}
      />
    </Button>
  );
};

export default RightButton;
