/* eslint-disable */
/* eslint-disable react/no-array-index-key */
import { Button, Modal } from "@mui/material";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import PageLoader from "../../../components/Loaders/PageLoader";
import NewRegisterModal from "../../../components/Modals/NewRegisterModal";
import PageError from "../../../components/Reuseable/PageError";
import PageHeader from "../../../components/Reuseable/PageHeader";
import ShowTeamsAwaitingApproval from "../../../components/ShowTeamsAwaitingApproval";
import { handleModuleResponseV4 } from "../../../helpers";
import useAllModulesV2 from "../../../reactQueryHooks/useAllModulesV2";
import { GlobalState } from "../../../store/global";
import ScrollableRowV2 from "./ScrollableRowV2";
import { Search } from "react-feather";
import SearchModal from "./V3/SearchModal";
import FeedEmpty from "./V3/FeedEmpty";
import FilterBrandsSelect from "./V3/FilterBrandsSelect";
import FilterModuleTypeSelect from "./V3/FilterModuleTypeSelect";
import useScrollPosition from "../../../hooks/useScrollPosition";

const MODULE_TYPES = {
  ALL: "all",
  ASSIGNED: "assigned",
  EXTRA_CREDIT: "extraCredit",
};

const StickyWrapper = styled.div`
  position: sticky;
  top: 64px;
  z-index: 100;

  transition: 240ms ease-in;
`;

const Wrapper = styled.div`
  padding: 20px 20px 60px 20px;
  max-width: 100vw;
  position: relative;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 580px) {
    display: none;
  }
`;

const SearchButton = styled(Button)`
  background: #337ab7;

  padding: 0px 12px;
  height: 32px;
  border: 1px solid lightgray;
  border-radius: 10px;

  span {
    color: #eeeeee;
    font-weight: 600;
    font-size: 12px;
  }

  &:hover {
    background: #4086c2;

    span {
      color: white;
    }
  }
`;

// const CustomChip = styled.div`
//   min-width: 100px;
//   padding: 4px 8px;
//   border-radius: 4px;
//   background-color: ${(props) =>
//     props.active === "yes"
//       ? props.theme.palette.background.paper
//       : props.theme.palette.background.contrast};
//   display: flex;
//   align-items: center;
//   border: 1px solid
//     ${(props) => (props.active === "yes" ? "lightgray" : "transparent")};
//   justify-content: center;
//   margin-right: 10px;
//   font-size: 14px;
//   cursor: pointer;
//   margin-left: 2px;

//   p {
//     font-size: 14px;
//     font-weight: ${(props) => (props.active === "yes" ? 500 : 400)};
//     color: ${(props) => (props.active === "yes" ? "black" : "slategray")};
//     margin-right: 4px;
//   }

//   span {
//     font-size: ${(props) => (props.active === "yes" ? 12 : 12)}px;
//     font-weight: ${(props) => (props.active === "yes" ? 500 : 300)};
//   }
// `;

const filterFeedForCompany = (feed, companyFilter) => {
  if (companyFilter && companyFilter?.id === 0) {
    return feed;
  }

  return feed.filter((row) => row.id === companyFilter?.id);
};

const getFilteredFeed = (feedArray, showModules, companyFilter) => {
  if (showModules === MODULE_TYPES.ALL)
    return filterFeedForCompany(feedArray, companyFilter);

  if (showModules === MODULE_TYPES.ASSIGNED) {
    const updatedFeedArray = [];

    feedArray.forEach((obj) => {
      const filteredObj = {
        ...obj,
      };

      filteredObj.quizzes = obj?.quizzes?.filter((mod) => mod.assigned);

      const cleanStreams = [];

      obj.streams.forEach((stream) => {
        const hasAtLeast1AssignedMod = stream.quizzes.some(
          (mod) => mod.assigned
        );

        if (hasAtLeast1AssignedMod) {
          const modulesClean = stream.quizzes.filter((mod) => mod.assigned);

          cleanStreams.push({
            ...stream,
            quizzes: modulesClean,
          });
        }
      });

      filteredObj.streams = cleanStreams;
      {
        if (filteredObj.streams.length > 0 || filteredObj.quizzes.length > 0) {
          updatedFeedArray.push(filteredObj);
        }
      }
    });

    return filterFeedForCompany(updatedFeedArray, companyFilter);
  }

  if (showModules === MODULE_TYPES.EXTRA_CREDIT) {
    const updatedFeedArray = [];

    feedArray.forEach((obj) => {
      const filteredObj = {
        ...obj,
      };

      filteredObj.quizzes = obj?.quizzes?.filter((mod) => !mod.assigned);

      const cleanStreams = [];

      obj.streams.forEach((stream) => {
        const hasAtLeastECMod = stream.quizzes.some((mod) => !mod.assigned);

        if (hasAtLeastECMod) {
          const modulesClean = stream.quizzes.filter((mod) => !mod.assigned);

          cleanStreams.push({
            ...stream,
            quizzes: modulesClean,
          });
        }
      });

      filteredObj.streams = cleanStreams;
      {
        if (filteredObj.streams.length > 0 || filteredObj.quizzes.length > 0) {
          updatedFeedArray.push(filteredObj);
        }
      }
    });

    return filterFeedForCompany(updatedFeedArray, companyFilter);
  }
};

const Learn = () => {
  const { showRegisterModal, setShowRegisterModal } = useContext(GlobalState);
  const { data, isLoading, isError } = useAllModulesV2();
  const [showModules, setShowModules] = useState(MODULE_TYPES.ALL);
  const [searchOpen, setSearchOpen] = useState(false);
  const [companyFilter, setCompanyFilter] = useState({
    id: 0,
    name: "All",
  });

  const scrollBreakpointPassed = useScrollPosition();

  console.log(scrollBreakpointPassed);

  if (isLoading) {
    return (
      <>
        <PageHeader text="Learn Feed" />
        <PageLoader />
      </>
    );
  }

  if (isError) {
    return <PageError />;
  }

  const { feedArray, allModules, allTeams, allBrands } =
    handleModuleResponseV4(data);

  const feedArrayWithFilterApplied = getFilteredFeed(
    feedArray,
    showModules,
    companyFilter
  );

  const getNextModuleInfo = (id) => {
    const index = allModules.findIndex((mod) => mod.id == id);
    const nextModuleIds = allModules.splice(index + 1).map((mod) => mod.id);
    return nextModuleIds.length > 0 ? nextModuleIds : null;
  };

  return (
    <>
      <ShowTeamsAwaitingApproval />
      <StickyWrapper
        style={{
          // background: !scrollBreakpointPassed ? "transparent" : "#ffffff99",
          background: !scrollBreakpointPassed ? "transparent" : "#ffffffd1",

          borderTop: !scrollBreakpointPassed
            ? "1px solid transparent"
            : "1px solid #eeeeee",
        }}
      >
        <PageHeader text="Learn Feed">
          <FlexDiv>
            <FilterModuleTypeSelect
              showModules={showModules}
              setShowModules={setShowModules}
            />
            <div style={{ width: 12 }} />
            <FilterBrandsSelect
              companyFilter={companyFilter}
              setCompanyFilter={setCompanyFilter}
              allTeams={allTeams}
              allBrands={allBrands}
              showModules={showModules}
            />
            <div
              style={{
                height: 28,
                width: 1,
                background: "lightgray",
                marginRight: 8,
                marginLeft: 8,
              }}
            />
            <SearchButton
              size="small"
              startIcon={<Search height={15} width={15} color="#eeeeee" />}
              onClick={() => setSearchOpen(true)}
            >
              <span>Search Modules</span>
            </SearchButton>
          </FlexDiv>
        </PageHeader>
      </StickyWrapper>

      <Wrapper>
        {feedArrayWithFilterApplied.length > 0 ? (
          feedArrayWithFilterApplied.map((item) => (
            <ScrollableRowV2
              item={item}
              key={item.id}
              getNextModuleInfo={getNextModuleInfo}
            />
          ))
        ) : (
          <FeedEmpty
            showModules={showModules}
            MODULE_TYPES={MODULE_TYPES}
            companyFilter={companyFilter}
          />
        )}
      </Wrapper>
      <Modal
        open={showRegisterModal}
        onClose={() => setShowRegisterModal(false)}
        aria-labelledby="modal-new-register-welcome"
        aria-describedby="modal-to-welcome-new-register"
      >
        <div>
          <NewRegisterModal setShowRegisterModal={setShowRegisterModal} />
        </div>
      </Modal>
      <Modal
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
        disableRestoreFocus
      >
        <div>
          <SearchModal allModules={allModules} />
        </div>
      </Modal>
    </>
  );
};

export default Learn;

{
  /* <CustomChip
              active={showModules === MODULE_TYPES.ALL ? "yes" : "no"}
              onClick={() => setShowModules(MODULE_TYPES.ALL)}
            >
              <p>All Modules</p>
            </CustomChip>
            <CustomChip
              active={showModules === MODULE_TYPES.ASSIGNED ? "yes" : "no"}
              onClick={() => setShowModules(MODULE_TYPES.ASSIGNED)}
            >
              <p>Assigned</p>
            </CustomChip>
            <CustomChip
              active={showModules === MODULE_TYPES.EXTRA_CREDIT ? "yes" : "no"}
              onClick={() => setShowModules(MODULE_TYPES.EXTRA_CREDIT)}
            >
              <p>Extra Credit</p>
            </CustomChip> */
}

//const Centered = styled.div`
//   height: 60vh;
//   min-height: 300px;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
