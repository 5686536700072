/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router-dom";
import BRAND_SVG from "../../../assets/svg/brands.svg";
import TEAM_SVG from "../../../assets/svg/teams.svg";
import DisplayModulesV3 from "./V3/DisplayModulesV3";
import DisplayStreamsV3 from "./V3/DisplayStreamsV3";

const ScrollableRowV2 = ({ item, getNextModuleInfo }) => {
  const navigate = useNavigate();
  const hasModules = item?.quizzes.length > 0;
  const hasStreams = item?.streams?.length > 0;

  return (
    <div
      style={{
        width: "100%",
        marginBottom: 20,
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        border: "1px solid lightgray",
        paddingBottom: 20,
        background: "white",
      }}
    >
      <div
        style={{
          width: "100%",
          background: "#eeeeee",
          // border: "1px solid lightgray",
          borderRadius: 6,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 8,
          }}
        >
          <img
            src={item.logo}
            style={{
              height: 44,
              width: 44,
              background: "white",
              border: "1px solid lightgray",
              borderRadius: 6,
              objectFit: "contain",
            }}
          />
          <span style={{ fontWeight: "600", fontSize: 15 }}>{item.name}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2px 6px",
            borderRadius: 3,
            background: "lightgray",
            marginRight: 4,
            marginLeft: "auto",
            gap: 4,
          }}
        >
          <img
            src={item?.isTeam ? TEAM_SVG : BRAND_SVG}
            style={{
              objectFit: "contain",
              height: item?.isTeam ? 16 : 11,
              width: item?.isTeam ? 16 : 11,
              borderRadius: 6,
            }}
          />

          <span
            style={{
              textTransform: "uppercase",
              color: "slategrey",
              fontSize: 10,
            }}
          >
            {item?.isTeam ? "Team" : "Brand"}{" "}
          </span>
        </div>
      </div>

      <div
        style={{
          padding: 4,
        }}
      >
        {hasStreams &&
          item.streams.map((stream) => (
            <DisplayStreamsV3
              stream={stream}
              navigate={navigate}
              key={stream?.name}
              getNextModuleInfo={getNextModuleInfo}
              companyName={item.name}
            />
          ))}
        {hasModules && (
          <div style={{ padding: "0px 4px" }}>
            <DisplayModulesV3
              modules={item.quizzes}
              navigate={navigate}
              getNextModuleInfo={getNextModuleInfo}
              companyName={item.name}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ScrollableRowV2;
