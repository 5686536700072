/* eslint-disable */
import { useContext, createContext, useState } from 'react';

export const snackContext = createContext();

export const SnackProvider = ({ children }) => {

  const snack = useSnackProvider();
  
  return (
    <snackContext.Provider value={{snack}}>
      {children}
    </snackContext.Provider>
  )
}

const useSnackProvider = () => {
  const [snackText, setSnackText] = useState(null);
  const [snackType, setSnackType] = useState(null)


  const SNACK_TYPES = {
    ERROR: 'error',
    SUCCESS: 'success',
    WARNING: 'warning',
  }

  const setSnack = (text, type = SNACK_TYPES.SUCCESS) => {
    setSnackType(type)
    setSnackText(text);
  }

  const closeSnack = () => {
    setSnackText(null);
    setSnackType(null);
  }
  

  return {
    snackText, 
    snackType,
    setSnack,
    closeSnack,
    SNACK_TYPES,
  }
}

const useSnack = () => useContext(snackContext)

export default useSnack;