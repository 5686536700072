/* eslint-disable */
import React from 'react';
import { Button, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AcceptOptions = ({
  setStep,
  token,
}) => {
  const navigate = useNavigate();
  return (
    <>
    <Typography sx={{ mt: 4, fontSize: 20, fontWeight: '600', color: 'black', }} align="center">
      You have been Referred to Spiffy!
    </Typography>
    <Typography style={{ fontSize: 12, color: 'darkgrey', marginTop: 16}} align="center">
      Do you already have an account with us?
    </Typography>
    <div style={{ marginTop: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 12}}>
      <Button
        variant="contained"
        size="small"
        onClick={() => setStep(1)}
      >
        Yes
      </Button>
      <Button
        variant="contained"
        size="small"
        onClick={() => navigate(`/register/?ref=${token}`)}
      >
        No
      </Button>
    </div>
    
    </>
  )
}

export default AcceptOptions