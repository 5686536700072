/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { useDebouncedCallback } from "use-debounce";

const DisplayText = ({ numModules }) => {
  const visibility = useContext(VisibilityContext);
  const { items } = visibility;

  const [visibleIndexes, setVisibleIndexes] = useState([]);

  const callbackFn = useDebouncedCallback(() => {
    setVisibleIndexes(items.getVisible());
  }, 50);

  useEffect(() => {
    items.subscribe("onUpdate", callbackFn);

    return () => {
      items.unsubscribe("onUpdate", callbackFn);
    };
  }, [items]);

  if (visibleIndexes.length === 0)
    return (
      <span style={{ color: "darkgrey" }}>Displaying {numModules} modules</span>
    );

  const visibleIndexesAsIndex = visibleIndexes.map((obj) =>
    Number(obj[1].index)
  );

  const firstVisibleIndex = visibleIndexesAsIndex[0] + 1;
  const lastVisibleIndex =
    visibleIndexesAsIndex[visibleIndexesAsIndex.length - 1] + 1;

  return (
    <span style={{ color: "darkgrey" }}>
      Displaying ({firstVisibleIndex}-{lastVisibleIndex}) of {numModules}{" "}
      modules
    </span>
  );
};

export default DisplayText;
