import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from '@mui/material';
import AppStoreBtn from '../../assets/appstore-black.svg';
import GoolePlayBtn from '../../assets/google-play-badge.png';
import MOBILE from '../../assets/svg/mobile.svg';
import { APPSTORE_LINK, PLAYSTORE_LINK } from '../../constants/secret';

const Wrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${props => props.theme.palette.background.paper};
  margin: auto auto 16px auto;
  padding: 10px;
  border: 1px solid lightgray;
`;

const HyperLink = styled.a`
  text-decoration: none;
`;

const CustomImgApple = styled.img`
  width: 100px;
`;

const CustomImgGooglePlay = styled.img`
  width: 120px;
  height: 48px;
`;

const AlignImages = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const SvgImage = styled.img`
  height: 26px;
  width: 26px;
  margin: auto;
`;

const GetOurApp = () => (
  <Wrapper>
    <SvgImage src={MOBILE} />
    <Typography variant="subtitle2" align="center">
      Get our mobile app here 👇
    </Typography>
    <AlignImages>
      <HyperLink target="_blank" href={APPSTORE_LINK}>
        <CustomImgApple src={AppStoreBtn} alt="Get it on apple appstore" />
      </HyperLink>
      <HyperLink target="_blank" href={PLAYSTORE_LINK}>
        <CustomImgGooglePlay src={GoolePlayBtn} alt="Get it on google playstore" />
      </HyperLink>
    </AlignImages>
  </Wrapper>
);

export default GetOurApp;
