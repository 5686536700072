/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Paper,
  Typography,
  LinearProgress,
} from "@mui/material";
import { ChevronDown, AlertTriangle, Clock, Calendar } from "react-feather";
import REDX from "../../../../assets/redx.png";
import GREENCHECK from "../../../../assets/check.png";
import CompletionMedia from "./CompletionMedia";
import CompletionResults from "./CompletionResults";
import useCompletionDetail from "../../../../reactQueryHooks/useCompletionDetail";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 440px;
  padding: 20px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background.main};
  max-height: 90vh;
  // border: 2px solid red;
  overflow-y: scroll;
`;

const FlexDiv = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CustomCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 0px;
  z-index: 1;

  .MuiCircularProgress-circleDeterminate {
    color: mediumseagreen;
    z-index: 1;
  }
`;

const CustomCircularProgressGrey = styled(CustomCircularProgress)`
  z-index: 0;

  .MuiCircularProgress-circleDeterminate {
    color: lightgray;
    z-index: 0;
  }
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  background: white;
  border-radius: 6px;
  padding: 0px 10px;
  margin-bottom: 4px;

  &:before {
    background-color: transparent;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0px;
  border: none;
  background: transparent;
`;

const ModuleImage = styled.img`
  height: 300px;
  width: 300px;
  object-fit: contain;
  background-color: white;
  border-radius: 4px;
  border-bottom-right-radius: 0px;
`;

const Top = styled(Paper)`
  border-radius: 4px;
  width: 100%;
  height: 301px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
  border: 1px solid #eeeeee;
`;

const Bottom = styled.div`
  padding: 0px;
`;

const ShowScoreWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 100px;
  padding: 10px;
  border-radius: 8px;
  margin-top: 12px;
  border: 1px solid black;
`;

const getScoreAchieved = (questions, answers) => {
  let correct = 0;
  const correctIndexes = [];
  const cleanAnswers = answers.map((a) => JSON.parse(a));
  try {
    questions.forEach((question, index) => {
      if (question.type === "tf") {
        if (
          question.correctAnswer.toLowerCase() ===
          cleanAnswers[index].toLowerCase()
        ) {
          correct += 1;
          correctIndexes.push(index);
        }
      }

      if (question.type === "mc") {
        if (
          question.correctAnswer.toLowerCase() ===
          cleanAnswers[index].toLowerCase()
        ) {
          correct += 1;
          correctIndexes.push(index);
        }
      }

      if (question.type === "sa") {
        if (
          question.correctAnswer.length === cleanAnswers[index].length &&
          question.correctAnswer.every((val) =>
            cleanAnswers[index].includes(val.toLowerCase())
          )
        ) {
          correct += 1;
          correctIndexes.push(index);
        }
      }
    });

    return {
      score: correct,
      correctIndexes,
    };
  } catch (error) {
    return {
      score: correct,
      correctIndexes: [],
    };
  }
};

const CompletionDetails = ({ reviewInfo }) => {
  const { id, logo } = reviewInfo || {};

  const [expandedIndex, setExpandedIndex] = useState(0);

  const { isLoading, error, data } = useCompletionDetail(id);

  if (!id) return null;

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          flex: 1,
          minHeight: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <span style={{ color: "darkgrey" }}>loading submission...</span>
        <LinearProgress
          variant="indeterminate"
          style={{ width: 160, borderRadius: 2 }}
          color="secondary"
        />
      </div>
    );
  }

  if (error)
    return (
      <div
        style={{
          width: "100%",
          flex: 1,
          minHeight: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <AlertTriangle color="firebrick" />
        <span style={{ color: "black", fontSize: 14, fontWeight: "500" }}>
          Error loading this submission
        </span>
      </div>
    );

  const parsedQuestions = JSON.parse(data.quiz__questions);
  const numQuestions = parsedQuestions.questions.length;
  const { score, correctIndexes } = getScoreAchieved(
    parsedQuestions.questions,
    data.user_answers
  );
  const percentage = Math.floor((score / numQuestions) * 100);

  return (
    <Wrapper>
      <div
        style={{
          background: "white",
          padding: "2px 6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          justifySelf: "flex-start",
          width: "fit-content",
          borderRadius: 3,
          gap: 4,
        }}
      >
        <Calendar height={12} width={12} color="slategrey" />
        <span style={{ fontSize: 12, color: "black", fontWeight: "500" }}>
          {new Date(data.created).toDateString()}
        </span>
      </div>
      <div style={{ height: 12 }} />
      <StyledAccordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expandedIndex === 0}
        style={{
          border:
            expandedIndex === 0 ? "1px solid #eeeeee" : "1px solid transparent",
        }}
        onChange={() => {
          if (expandedIndex === 0) {
            return setExpandedIndex(null);
          }
          return setExpandedIndex(0);
        }}
      >
        <StyledAccordionSummary expandIcon={<ChevronDown />}>
          <span
            style={{
              fontSize: 14,
              fontWeight: "500",
              color: expandedIndex === 0 ? "black" : "slategrey",
            }}
          >
            Module Info
          </span>
        </StyledAccordionSummary>
        <AccordionDetails style={{ overflowY: "scroll", maxHeight: 440 }}>
          <Top>
            <ModuleImage src={logo} alt="module" />
            <div
              style={{
                padding: 12,
                flexGrow: 1,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography
                align="left"
                style={{
                  fontSize: 11,
                  color: "darkgrey",
                  textTransform: "uppercase",
                  fontWeight: 500,
                }}
              >
                {data.quiz__company__name}
              </Typography>

              <span
                style={{
                  textAlign: "left",
                  fontSize: 20,
                  fontWeight: 600,
                  lineHeight: 1.2,
                }}
              >
                {data.quiz__name}
              </span>

              <div
                style={{
                  width: "100%",
                  maxHeight: 100,
                  overflowY: "scroll",
                  lineHeight: 1.4,
                  textAlign: "left",
                  padding: "2px ",
                }}
              >
                <span style={{ textAlign: "left" }}>
                  {data.quiz__description}
                </span>
              </div>
              <ShowScoreWrapper>
                <div
                  style={{
                    flexGrow: 1,
                    width: "100%",
                    height: "100%",
                    marginRight: 10,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "600",
                      color: "black",
                      marginTop: -2,
                      marginBottom: 4,
                    }}
                  >
                    You Passed
                  </span>
                  <div
                    style={{
                      width: "100%",
                      minHeight: 45,
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    {[...Array(numQuestions).keys()].map((q, i) => (
                      <div
                        key={q.id}
                        style={{
                          marginRight: 6,
                          background: "#eeeeee",
                          position: "relative",
                          border: "1px solid black",
                          borderRadius: 4,
                          height: 40,
                          width: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span style={{ fontSize: 16, fontWeight: "600" }}>
                          {i + 1}
                        </span>
                        <img
                          style={{
                            position: "absolute",
                            top: -4,
                            right: -4,
                            height: 12,
                            width: 12,
                            borderRadius: 6,
                            objectFit: "contain",
                          }}
                          src={correctIndexes.includes(i) ? GREENCHECK : REDX}
                          alt="check or x"
                        />
                      </div>
                    ))}
                  </div>
                  <span style={{ fontSize: 10, color: "white" }}>
                    Submission Date: {new Date(data.created).toDateString()}
                  </span>
                </div>
                <FlexDiv>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      height: 80,
                      width: 80,
                      borderRadius: 40,
                      backgroundColor: "white",
                    }}
                  >
                    <CustomCircularProgressGrey
                      value={100}
                      size={80}
                      variant="determinate"
                    />
                    <CustomCircularProgress
                      value={percentage}
                      size={80}
                      variant="determinate"
                    />
                    <span
                      style={{
                        fontSize: 12,
                        color: "darkgrey",
                        lineHeight: 1.1,
                        fontWeight: 500,
                      }}
                    >
                      Scored
                    </span>
                    <span style={{ fontSize: 20, fontWeight: 800 }}>
                      {score}/{numQuestions}
                    </span>
                  </div>
                </FlexDiv>
              </ShowScoreWrapper>
            </div>
          </Top>
        </AccordionDetails>
      </StyledAccordion>
      <Bottom>
        <StyledAccordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expandedIndex === 1}
          style={{
            border:
              expandedIndex === 1
                ? "1px solid #eeeeee"
                : "1px solid transparent",
          }}
          onChange={() => {
            if (expandedIndex === 1) {
              return setExpandedIndex(null);
            }
            return setExpandedIndex(1);
          }}
        >
          <StyledAccordionSummary expandIcon={<ChevronDown />}>
            <span
              style={{
                fontSize: 14,
                fontWeight: "500",
                color: expandedIndex === 1 ? "black" : "slategrey",
              }}
            >
              Module Content
            </span>
          </StyledAccordionSummary>
          <AccordionDetails style={{ overflowY: "scroll", maxHeight: 440 }}>
            <CompletionMedia data={data} />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expandedIndex === 2}
          style={{
            border:
              expandedIndex === 2
                ? "1px solid #eeeeee"
                : "1px solid transparent",
          }}
          onChange={() => {
            if (expandedIndex === 2) {
              return setExpandedIndex(null);
            }
            return setExpandedIndex(2);
          }}
        >
          <StyledAccordionSummary expandIcon={<ChevronDown />}>
            <span
              style={{
                fontSize: 14,
                fontWeight: "500",
                color: expandedIndex === 2 ? "black" : "slategrey",
              }}
            >
              Your Answers
            </span>
          </StyledAccordionSummary>
          <AccordionDetails style={{ overflowY: "scroll", maxHeight: 400 }}>
            <CompletionResults data={data} />
          </AccordionDetails>
        </StyledAccordion>
      </Bottom>
    </Wrapper>
  );
};

export default CompletionDetails;

CompletionDetails.propTypes = {
  reviewInfo: PropTypes.object,
};

CompletionDetails.defaultProps = {
  reviewInfo: null,
};
