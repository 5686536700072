/* eslint-disable */
import React from 'react';
import useUserData from '../reactQueryHooks/useUserData';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

const ShowTeamsAwaitingApproval = () => {

  const { data, isLoading } = useUserData();
  const navigate = useNavigate();
  
  if (isLoading) return null;

  const { teams } = data || {};

  if (teams.length === 0) {
    return (
      <div style={{ width: '100%', height: 60, backgroundColor: 'dodgerblue', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', padding: '0px 20px'}}>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'}}>
          <span style={{ color: 'white', fontSize: 15, fontWeight: 600, lineHeight: 1.3}}>YOU HAVE NO TEAMS</span>
          <span style={{ color: 'white', fontSize: 12, fontWeight: 400, lineHeight: 1.3}}>
            Without a team, you will not receive any assigned modules. You can only complete extra credit content.
          </span>
        </div>
        <Button
          size="small"
          onClick={() =>  navigate('/profile', { state: { openAddTeamModal : true }})}
          style={{ backgroundColor: 'white', color: 'dodgerblue'}}
        >
          Add Team
        </Button>
      </div>
    )
  }

  const teamsUnapproved = teams.filter(t => !t.approved);

  if (teamsUnapproved.length === 0 || !teamsUnapproved) return null;

  const unnaprovedTeamNamesFormatted = teamsUnapproved.map(t => t.company__name).join(" & ")


  return (
    <div style={{ width: '100%', height: 60, backgroundColor: '#fe891b', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column', padding: '0px 20px'}}>
      <span style={{ color: 'white', fontSize: 15, fontWeight: 600, lineHeight: 1.3, textTransform: 'uppercase'}}>AWAITING APPROVAL: {unnaprovedTeamNamesFormatted} </span>
      <span style={{ color: 'white', fontSize: 12, fontWeight: 400, lineHeight: 1.3}}>
        You will not receive modules from {teamsUnapproved > 1 ? 'these teams' : 'this team'} until you have been approved by an admin.
      </span>
    </div>
  )
}

export default ShowTeamsAwaitingApproval