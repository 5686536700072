/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import ShowPdf from "../../../Reuseable/ShowPdf";
import ShowVideo from "../../../Reuseable/ShowVideo";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const InnerWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HoldVideo = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: fit-content;
`;

const Indicator = ({ title = "", isActive }) => (
  <div
    style={{
      minWidth: 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <div
      style={{
        height: 14,
        width: 14,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 2,
        border: `1px solid ${
          isActive ? "lightseagreen" : "rgba(255,192,203, .8)"
        }`,
        marginRight: 8,
        background: isActive
          ? "rgba(146, 255, 146, 0.18)"
          : "rgba(255,192,203, .4)",
      }}
    >
      <div
        style={{
          height: 7,
          width: 7,
          borderRadius: "50%",
          backgroundColor: isActive ? "mediumseagreen" : "tomato",
        }}
      />
    </div>
    <span style={{ fontWeight: 500, letterSpacing: 1, fontSize: 14 }}>
      {title}
    </span>
  </div>
);

Indicator.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

const CompletionMedia = ({ data }) => {
  const hasPdf = Boolean(data.quiz__media);
  const hasVideo = Boolean(data.quiz__video);

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 16,
          marginBottom: 12,
        }}
      >
        <Indicator isActive={hasPdf} title="PDF" />
        <Indicator isActive={hasVideo} title="Video" />
      </div>
      {hasPdf && (
        <InnerWrapper>
          <ShowPdf file={data.quiz__media} />
        </InnerWrapper>
      )}
      {hasVideo && (
        <InnerWrapper>
          <HoldVideo>
            <ShowVideo videoId={data.quiz__video} width={340} height={200} />
          </HoldVideo>
        </InnerWrapper>
      )}
    </Wrapper>
  );
};

export default CompletionMedia;

CompletionMedia.propTypes = {
  data: PropTypes.object.isRequired,
};
