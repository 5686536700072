/* eslint-disable */
import { useEffect, useState } from "react";

const BREAKPOINT = 64;

const useWindowScrollPositions = () => {
  const [scrollBreakpointPassed, setScrollBreakpointPassed] = useState(false);

  useEffect(() => {
    function updatePosition() {
      if (window.scrollY > BREAKPOINT) {
        setScrollBreakpointPassed(true);
      } else {
        setScrollBreakpointPassed(false);
      }
      // setPosition({ scrollX: window.scrollX, scrollY: window.scrollY });
    }

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return scrollBreakpointPassed;
};

export default useWindowScrollPositions;
