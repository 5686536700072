/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
} from '@mui/material';
import { addDays, isSameDay, lightFormat } from 'date-fns';
import styled from 'styled-components/macro';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CustomTextField } from '../../../styles/forms';
import API from '../../../axios/API';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const formatDateForApi = date => lightFormat(date, 'yyyy-MM-dd');

const DateOfBirthForm = ({
  setSnack,
  SNACK_TYPES,
  profile,
  refetch,
  setExpandedIndex,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    profile.birth_date
      ? addDays(new Date(profile.birth_date), 1)
      : null,
  );

  const isDisabled = !selectedDate
  || isSameDay(selectedDate, addDays(new Date(profile.birth_date), 1));

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      await API.patch('accounts/profile/', {
        birth_date: formatDateForApi(selectedDate),
      });
      await refetch();
      setIsSubmitting(false);
      setExpandedIndex(null)
      setSnack('Successfully updated your date of birth', SNACK_TYPES.SUCCESS);
    } catch (error) {
      setIsSubmitting(false);
      setSnack('Failed to update your date of birth', SNACK_TYPES.ERROR);
    }
  };

  return (
    <Wrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Date of Birth"
          renderInput={params => <CustomTextField size="small" fullWidth {...params} sx={{ mb: 4 }} />}
          value={selectedDate}
          onChange={newValue => setSelectedDate(newValue)}
        />
      </LocalizationProvider>
      <Button
        type="submit"
        size="small"
        variant="contained"
        color="primary"
        disabled={isDisabled}
        startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        sx={{
          marginTop: 'auto',
          marginLeft: 'auto',
        }}
        onClick={handleSave}
      >
        Save
      </Button>
    </Wrapper>
  );
};

export default DateOfBirthForm;

DateOfBirthForm.propTypes = {
  setSnack: PropTypes.func.isRequired,
  SNACK_TYPES: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
