/* eslint-disable */
import { useQuery } from "react-query";
import API from "../axios/API";

const ENDPOINT = "/quizzes/?version=4";

const getModules = async () => {
  const { data } = await API.get(ENDPOINT);
  return data;
};

const useAllModulesV2 = () => useQuery("allModulesV2", getModules);

export default useAllModulesV2;
