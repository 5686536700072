/* eslint-disable */
import React, { useContext } from "react";
import { ArrowLeft } from "react-feather";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { Button } from "@mui/material";

const LeftButton = () => {
  const visibility = useContext(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <Button
      style={{
        height: 33,
        width: 33,
        maxWidth: 36,
        border: "1px solid lightgray",
        minWidth: 0,
        padding: 0,
        borderRadius: 8,
        background: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
    >
      <ArrowLeft
        color={isFirstItemVisible ? "darkgrey" : "black"}
        height={18}
        width={18}
      />
    </Button>
  );
};

export default LeftButton;
