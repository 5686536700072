/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import { ChevronRight, X, ChevronLeft } from "react-feather";
import {
  IconButton,
  Typography,
  CircularProgress,
  Button,
  ClickAwayListener,
} from "@mui/material";
import styled from "styled-components/macro";
import useWindowSize from "../../hooks/useWindowSize";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ModalWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 600px;
  background-color: ${(props) => props.theme.palette.background.contrast};
  overflow-y: scroll;
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: center;
`;

const RelativeIconButton = styled(IconButton)`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 100000;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
    color: black;
  }
`;

const FloatingPageDiv = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  background-color: ${(props) => props.theme.palette.background.paper};
  width: 180px;
  height: 40px;
  transform: translateX(-50%);
  display: flex;
  padding: 2px 0px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  opacity: ${(props) => (props.show === "yes" ? 1 : 0)};
  pointer-events: ${(props) => (props.show === "yes" ? "auto" : "none")};
  transition: opacity 200ms ease-in;
`;

const StyledDocument = styled(Document)`
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
`;

const SmallButton = styled(Button)`
  width: 30px;
`;

const Center = styled.div`
  height: 100vh;
  width: 574px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const NumPageSpan = styled.span`
  color: ${(props) => props.theme.palette.background.bw};
`;

const ShowPage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
`;

// Come back to this or delete
const LoadingThePdf = () => (
  <Center>
    <CircularProgress color="primary" size={30} />
  </Center>
);

const PdfError = () => (
  <Typography variant="subtitle1" color="warning">
    Error Loading Pdf
  </Typography>
);

const ShowPdfFullScreen = ({ setIsFullSize, SRC }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isHovering, setIsHovering] = useState(false);
  const { height } = useWindowSize();

  const onDocumentLoadSuccess = ({ numPages: numberPages }) => {
    setNumPages(numberPages);
  };

  const PDF_HEIGHT = height - 24;

  return (
    <ModalWrapper
      onMouseLeave={() => setIsHovering(false)}
      onMouseOver={() => setIsHovering(true)}
    >
      <ClickAwayListener onClickAway={() => setIsFullSize(false)}>
        <StyledDocument
          onLoadSuccess={onDocumentLoadSuccess}
          file={SRC}
          loading={<LoadingThePdf />}
          error={<PdfError />}

          // width={300}
        >
          <Page pageNumber={pageNumber} height={PDF_HEIGHT} />
          <FloatingPageDiv show={isHovering ? "yes" : "no"}>
            <SmallButton
              color="primary"
              startIcon={<ChevronLeft />}
              disabled={pageNumber === 1}
              onClick={() => setPageNumber((p) => p - 1)}
            />
            <ShowPage>
              <NumPageSpan>Page</NumPageSpan>
              <NumPageSpan>
                {pageNumber} of {numPages}
              </NumPageSpan>
            </ShowPage>
            <SmallButton
              disabled={pageNumber === numPages}
              startIcon={<ChevronRight />}
              onClick={() => setPageNumber((p) => p + 1)}
            />
          </FloatingPageDiv>
        </StyledDocument>
      </ClickAwayListener>
      <RelativeIconButton size="small" onClick={() => setIsFullSize(false)}>
        <X />
      </RelativeIconButton>
    </ModalWrapper>
  );
};

export default ShowPdfFullScreen;

ShowPdfFullScreen.propTypes = {
  setIsFullSize: PropTypes.func.isRequired,
  SRC: PropTypes.string.isRequired,
};
