/* eslint-disable */
import React, { useReducer } from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import styled from 'styled-components/macro';
import { ChevronLeft } from 'react-feather';
import useSnack from '../../../store/snack';
import FormOne from './FormOne';
import FormTwo from './FormTwo';
import PublicFormLayout from '../../../layouts/PublicFormLayout';


const Wrapper = styled(Paper)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  position: relative;

  @media (max-width: 420px) {
    width: 96vw;
    padding: 10px;
    min-width: 300px;
  }
`;

const RegisterSpan = styled.span`
  font-size: 11px;
  color: ${props => props.theme.palette.background.bw};
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  
  color: ${props => props.theme.palette.info.dark};
    text-decoration: none;

    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;


const ACTIONS = {
  UPDATE_COUNTRIES: 'UPDATE_COUNTRIES',
  COMPLETE_FIRST_STEP: 'COMPLETE_FIRST_STEP',
  SET_COMPANY: 'SET_COMPANY',
  SET_LOCATION: 'SET_LOCATION',
  SET_ROLES: 'SET_ROLES',
  TOGGLE_AGREED: 'TOGGLE_AGREED',
  BACK: 'BACK',
  TOGGLE_HAS_TEAM: 'TOGGLE_HAS_TEAM',

  SET_INDUSTRY: 'SET_INDUSTRY',
  SET_NEW_COUNTRY: 'SET_NEW_COUNTRY',
  SET_NEW_STATE: 'SET_NEW_STATE',
  SET_NEW_COMPANY_TEXT: 'SET_NEW_COMPANY_TEXT',
};

const reducer = (state, action) => {
  switch (action.type) {

    // First Form Data (FORM 1)
    case ACTIONS.COMPLETE_FIRST_STEP:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        countryCode: action.payload.countryCode,
        email: action.payload.email,
        ref: action.payload.ref,
        password: action.payload.password,
        confirmPassword: action.payload.confirmPassword,
        dob: action.payload.dob,
        index: 1,
      };

    /// User Has A Company (FORM 2)
    case ACTIONS.SET_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
        hasTeam: true,
        selectedRoles: [],
        selectedLocation: '',
        newSelectedCountry: '',
        newSelectedState: '',
        newSelectedIndustry: '',
        newCompanyText: '',
      };
    case ACTIONS.SET_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case ACTIONS.SET_ROLES:
      return {
        ...state,
        selectedRoles: action.payload,
      };


    case ACTIONS.UPDATE_COUNTRIES:
      return {
        ...state,
        countriesLoading: false,
        availCountries: action.payload,
      };

    case ACTIONS.TOGGLE_AGREED:
      return {
        ...state,
        agreedToTerms: action.payload,
      };

    case ACTIONS.TOGGLE_HAS_TEAM: 
      return {
        ...state,
        hasTeam: !state.hasTeam,
      }
    /// User Does Not Have A Company (FORM 3)      
    case ACTIONS.SET_INDUSTRY:
      return {
        ...state, 
        newSelectedIndustry: action.payload,
      }
    
    case ACTIONS.SET_NEW_COUNTRY:
      return {
        ...state, 
        newSelectedCountry: action.payload,
        newSelectedState: '',
      } 

    case ACTIONS.SET_NEW_STATE: {
      return {
        ...state, 
        newSelectedState: action.payload,
      }
    }
    case ACTIONS.SET_NEW_COMPANY_TEXT: {
      return {
        ...state, 
        newCompanyText: action.payload,
      }
    }
    case ACTIONS.BACK:
      return {
        ...state,
        index: 0,
        selectedCompany: null,
        selectedLocation: '',
        selectedRoles: [],
        hasTeam: true,
      };
    default:
      return {
        ...state,
      };
  }
};



const Register = () => {
  
  const params = new URLSearchParams(location.search);
  const initialRef = params.has('ref') ? params.get('ref') : '';

  const initialState = {
    index: 0,
    firstName: null,
    lastName: null,
    email: null,
    confirmPassword: null,
    ref: initialRef,
    dob: null,
    availCountries: [],
    countriesLoading: true,
    selectedCompany: null,
    selectedLocation: '',
    selectedRoles: [],
    agreedToTerms: false,
    hasTeam: true,
  
    /// If has Team = false, this info is captured about new company
    newSelectedCountry: '',
    newSelectedState: '',
    newSelectedIndustry: '',
    newCompanyText: '',
  };


  const [formState, dispatch] = useReducer(reducer, initialState);
  const { snack } = useSnack();

  const {
    index,
  } = formState;

  return (
    <PublicFormLayout>
      <Wrapper>
        <FlexDiv>
          <div>
            <Typography variant="h2" sx={{ mt: 2 }} align="left">
              Sign Up
            </Typography>
          </div>
          {
            index === 1 ? (
              <IconButton
                size="small"
                onClick={() => dispatch({ type: ACTIONS.BACK })}
              >
                <ChevronLeft />
              </IconButton>
            ) : <div />
          }
        </FlexDiv>
        {
          index === 0 && (
            <>
              <FormOne formState={formState} dispatch={dispatch} ACTIONS={ACTIONS} />
            </>
          )
        }
        {
          index === 1 && (
            <>
              <FormTwo dispatch={dispatch} formState={formState} ACTIONS={ACTIONS} snack={snack} />
            </>
          )
        }
        <RegisterSpan>Already have an account? <Link to="/signin/">Log In</Link></RegisterSpan>
      </Wrapper>
    </PublicFormLayout>
  );
};

export default Register;
