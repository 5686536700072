/* eslint-disable */
import React, { useState, useMemo } from "react";
import styled from "styled-components/macro";
import { Paper, TextField, Button } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  padding: 20px;
  display: flex;
  min-height: min(800px, 80vh);
  max-height: min(800px, 80vh);
  background-color: #eeeeee;
  flex-direction: column;
  border: 2px solid black;
`;

const ItemWrapper = styled.div`
  border: 1px solid lightgray;
  width: 100%;
  background: white;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  border-radius: 8px;
`;

const ItemImage = styled(LazyLoadImage)`
  height: 46px;
  width: 46px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  object-fit: contain;
`;

const ItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  height: 46px;
  overflow-y: hidden;
  text-overflow: ellipsis;
`;

const Item = ({ mod }) => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate(`/moduleV2/${mod.id}`);
  };

  return (
    <ItemWrapper>
      <ItemImage src={mod?.image} />
      <ItemTextContainer>
        <span style={{ color: "black", fontSize: 14, fontWeight: "500" }}>
          {mod?.name}
        </span>
        <span
          style={{
            fontSize: 12,
            color: "darkgrey",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "100%",
            overflowY: "hidden",
          }}
        >
          {mod?.company}
        </span>
      </ItemTextContainer>
      <div
        style={{
          height: 46,
          flexBasis: 60,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 4,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={handleStart}
        >
          Start
        </Button>
      </div>
    </ItemWrapper>
  );
};

const handleFilterFeed = (allModules, searchValue, searchActive) => {
  if (!searchActive) return [];

  const filteredByName = allModules?.filter(
    (mod) =>
      mod?.name.toLowerCase().includes(searchValue?.toLowerCase()) ||
      mod?.company?.toLowerCase().includes(searchValue.toLowerCase())
  );

  return filteredByName;
};

const SearchModal = ({ allModules }) => {
  const [searchValue, setSearchValue] = useState("");

  const searchActive = searchValue?.length > 2;

  const modulesFiltered = useMemo(
    () => handleFilterFeed(allModules, searchValue, searchActive),
    [searchValue]
  );

  return (
    <ModalWrapper>
      <span style={{ fontWeight: "600", fontSize: 16 }}>Search Modules</span>
      <div
        style={{
          height: 4,
          minHeight: 4,
          flexShrink: 0,

          width: "100%",
        }}
      />
      <TextField
        fullWidth
        value={searchValue}
        autoFocus
        autoComplete="off"
        onChange={(e) => setSearchValue(e.target.value)}
        inputProps={{
          style: {
            fontSize: 15,
            fontWeight: "400",
            background: "white",
          },
        }}
      />
      <div
        style={{
          marginTop: 6,
          marginBottom: 6,
          height: 1,
          width: "100%",
          background: "transparent",
        }}
      />
      <div
        style={{
          width: "100%",
          minHeight: 240,
          border: "1px solid lightgray",
          background: "#eeeeee50",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          borderRadius: 4,
          padding: "12px 6px",
          gap: 5,
          flex: 1,
          overflowY: "scroll",
        }}
      >
        {!searchActive && (
          <p style={{ color: "darkgrey", fontSize: 13 }}>..type to search..</p>
        )}
        {searchActive &&
          modulesFiltered.length > 0 &&
          modulesFiltered.map((mod) => <Item key={mod?.id} mod={mod} />)}
        {searchActive && modulesFiltered.length === 0 && (
          <p style={{ color: "darkgrey", fontSize: 13 }}>
            No results for search..
          </p>
        )}
      </div>
    </ModalWrapper>
  );
};

export default SearchModal;
